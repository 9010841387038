import React, {useEffect,useRef,useState} from "react";
import { useSearchParams } from "react-router-dom";

export default function CheckoutForm({
  orderQuantity,
  orderQuantity2,
  customerEmail,
  fullName,
  customer,
  plasticCams,
  yearlyDiscount,
}) {
  let form = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    form.current.submit();
  },[]);

  useEffect(() => {
    console.log(customer);
  },[customer]);

  return (
    <form name="checkout" ref={form} action={`${process.env.REACT_APP_PAY_URL}/create-checkout-session`} method='POST'>
        <input type="hidden" value={orderQuantity} name="orderQuantity" />
        <input type="hidden" value={orderQuantity2} name="orderQuantity2" />
        <input type="hidden" value={customerEmail} name="customerEmail" />
        <input type="hidden" value={searchParams.get("product_id")} name="productId" />
        <input type="hidden" value={fullName} name="fullName" />
        <input type="hidden" value={plasticCams} name="plastic" />
        <input type="hidden" value={yearlyDiscount} name="yearly" />
        {customer && (<input type="hidden" value={customer.id} name="customerId" />)}
    </form>
  )
}