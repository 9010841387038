import React, { useState, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";
import ProductSelector from "./ProductSelector";
import ESignature from "./ESignature";
import SuccessPage from "./SuccessPage";
import "./App.css";

export default function App() {
  const [formState, setFormState] = useState("productSelectionFirst");
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [orderQuantity2,setOrderQuantity2] = useState(0);
  const [customerEmail, setCustomerEmail] = useState("");
  const [documentId, setDocumentId] = useState(null);
  const [documentSigned, setDocumentSigned] = useState(false);
  const [fullName, setFullName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phone, setPhone] = useState("");
  const [customer, setCustomer] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [waitList, setWaitList] = useState(false);
  const [plasticCams, setPlasticCams] = useState(false);
  const [yearlyDiscount, setYearlyDiscount] = useState(false);

  useEffect(() => {
    setDocumentSigned(JSON.parse(sessionStorage.getItem('documentSigned')));
    setOrderQuantity(JSON.parse(sessionStorage.getItem('orderQuantity')));
    setOrderQuantity2(JSON.parse(sessionStorage.getItem('orderQuantity2')));
    setCustomerEmail(JSON.parse(sessionStorage.getItem('customerEmail')));
    setCustomer(JSON.parse(sessionStorage.getItem('customer')));
    setWaitList(JSON.parse(sessionStorage.getItem('waitList')));
    setPlasticCams(JSON.parse(sessionStorage.getItem('plastic')));
    setYearlyDiscount(JSON.parse(sessionStorage.getItem('yearly')));
  },[]);

  useEffect(() => {
    if(searchParams.get("session_id")) {
      setFormState("success");
    }
    let fourCam = searchParams.get("4cam");
    let twoCam = searchParams.get("2cam");
    if(fourCam) {
      setOrderQuantity(1);
      setOrderQuantity2(0);
    }
    if(twoCam) {
      setOrderQuantity2(1);
      setOrderQuantity(0);
    }
  },[searchParams]);

  useEffect(() => {
    if(customerEmail)
      sessionStorage.setItem('customerEmail',JSON.stringify(customerEmail));
    if(documentSigned!=null)
      sessionStorage.setItem('documentSigned',JSON.stringify(documentSigned));
    if(orderQuantity!=null)
      sessionStorage.setItem('orderQuantity',JSON.stringify(orderQuantity));
    if(orderQuantity2!=null)
      sessionStorage.setItem('orderQuantity2',JSON.stringify(orderQuantity2));
  },[documentSigned,customerEmail,orderQuantity,orderQuantity2]);

  useEffect(() => {
    if(businessName)
      sessionStorage.setItem('businessName',JSON.stringify(businessName));
    if(fullName)
      sessionStorage.setItem('customerName',JSON.stringify(fullName));
  },[businessName,fullName]);

  useEffect(() => {
    if(documentId)
      sessionStorage.setItem('documentId',JSON.stringify(documentId));
    if(waitList!=null) {
      sessionStorage.setItem('waitList',JSON.stringify(waitList));
    }
    if(plasticCams) {
      sessionStorage.setItem('plastic',JSON.stringify(plasticCams));
    }
  },[documentId,waitList,plasticCams]);

  useEffect(() => {
    if(customer)
      sessionStorage.setItem('customer',JSON.stringify(customer));
  },[customer]);

  useEffect(() => {
    if(yearlyDiscount!=null) {
      sessionStorage.setItem('yearly',JSON.stringify(yearlyDiscount));
    }
  },[yearlyDiscount]);
  
  useEffect(() => {
    if(orderQuantity && orderQuantity>0 && plasticCams==false) {
      setWaitList(true);
    }
  },[customer,orderQuantity])

  // const appearance = {
  //   theme: 'stripe',
  //   variables: {
  //     colorPrimary: '#297acc',
  //     colorText: '#131659',
  //   },
  // };
  // const options = {
  //   mode:'subscription',
  //   currency: 'usd',
  //   amount: subTotal,
  //   clientSecret:clientSecret,
  //   appearance:{
  //     theme: 'stripe',
  //     variables: {
  //       colorPrimary: '#297acc',
  //       colorText: '#131659',
  //     },
  //   }
  // };

  function formStateCase() {
    switch(formState) {
      case "productSelectionFirst":
        return (
          <ProductSelector 
            setFormState={setFormState} 
            setOrderQuantity={setOrderQuantity} 
            orderQuantity={orderQuantity}
            setOrderQuantity2={setOrderQuantity2}
            orderQuantity2={orderQuantity2}
            setCustomer={setCustomer}
            setPlasticCams={setPlasticCams}
            setYearlyDiscount={setYearlyDiscount}
          />
        )
      case "eSignatureSecond":
        return (
          <ESignature 
            setFormState={setFormState} 
            setCustomerEmail={setCustomerEmail} 
            customerEmail={customerEmail}
            setDocumentSigned={setDocumentSigned}
            documentSigned={documentSigned}
            setDocumentId={setDocumentId}
            fullName={fullName}
            setFullName={setFullName}
            businessName={businessName}
            setBusinessName={setBusinessName}
            phone={phone}
            setPhone={setPhone}
            customer={customer}
          />
        )
      case "billingThird":
        return (
          <CheckoutForm 
            orderQuantity={orderQuantity} 
            orderQuantity2={orderQuantity2}
            customerEmail={customerEmail}
            plasticCams={plasticCams}
            fullName={fullName}
            customer={customer}
            yearlyDiscount={yearlyDiscount}
          />
        )
      case "success":
        return (
          <SuccessPage 
            documentId={documentId}
            businessName={businessName}
            customerName={fullName}
            waitList={waitList}
          />
        )
      default:
        return null;
    }
  }

  return (
    <div className="App">
      <div className="center-screen">
        <div className="image-container">
          <img src="https://i.imgur.com/21O3DUG.png" alt="Bedrock Wireless" />
        </div>
        {formStateCase()}
      </div>
    </div>
  );
}